import {Box} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useEffect, useState} from 'react';
import rollbar from 'src/vendor/rollbar';
import allowSettingCustomDesignPreview from '../utils/previewUpload';
import productCardOptimizationEnabled from '../utils/productCardOptimization';
import {
  DEFAULT_UNITS_TEST_NUMBER,
  T_SHIRTS_SUBCATEGORIES_IDS
} from './constants/defaultUnitPrice';
import {ProductCardPlaceholders} from './Placeholders';
import UploadCard from './preview_design/UploadCard';
import EcoFriendlyBadge from './product_card/EcoFriendlyBadge';
import MadeInUSABadge from './product_card/MadeInUSABadge';
import ProductCard from './product_card/ProductCard';
import {ALWAYS_NEW_BADGE_PRODUCT_IDS} from './utils/algolia_constants';
import {getDefaultUnitPriceTestValue} from './utils/defaultUnitPricing';

const badgingNameMap = {
  bestSeller: 'best_seller_badge_ids',
  customerFavorite: 'customer_favorite_badge_ids',
  staffPick: 'staff_pick_badge_ids'
};

const getInitialCardCount = () => {
  let count = 6;
  if (window.innerHeight > 1140) count = 9;
  if (window.innerHeight > 1800) count = 12;
  if (window.innerHeight > 2400) count = 15;
  if (window.innerHeight > 3200) count = 21;
  return count;
};

const hasNewBadge = ({badges, hit}) => {
  return (
    (badges.new_badge_style_ids?.includes(parseInt(hit.product_id, 10)) &&
      !badges.excluded_new_badge_ids?.includes(parseInt(hit.product_id, 10))) ||
    ALWAYS_NEW_BADGE_PRODUCT_IDS.includes(parseInt(hit.product_id, 10))
  );
};

const hasRecommendationBadge = ({
  activeCategoryId,
  badgeName,
  badges,
  hit = {}
}) => {
  if (badges == null) {
    return false;
  }
  if (Object.keys(badges[badgingNameMap[badgeName]]).includes(hit.product_id)) {
    const hitBadgeArray = badges[badgingNameMap[badgeName]][hit.product_id];
    return hitBadgeArray.includes(parseInt(activeCategoryId, 10));
  }
  return false;
};

const lowerProductCardBadge = ({badges, hit}) => {
  // Early exit if badges are null
  if (!badges) return null;

  // Define badge types and associated components
  const badgeChecks = [
    {
      component: <EcoFriendlyBadge productName={hit.name} />,
      ids: badges.eco_style_badge_ids
    },
    {component: <MadeInUSABadge />, ids: badges.usa_style_badge_ids}
  ];

  // Find and return the first applicable badge component
  const applicableBadge = badgeChecks.find((badge) =>
    badge.ids?.includes(parseInt(hit.product_id, 10))
  );
  return applicableBadge ? applicableBadge.component : null;
};

const Badge = ({
  content,
  isMobile,
  recommendation = false,
  secondary = false
}) => {
  let className = 'pc-ProductCard-promoBadge';
  if (secondary) className += ' pc-ProductCard-promoBadge--secondary';
  if (recommendation) className += ' pc-ProductCard-promoBadge-recommendation';
  const isProductCardOptimizationTest = productCardOptimizationEnabled();
  return (
    <span
      className={className}
      style={{
        ...(isProductCardOptimizationTest && !isMobile && {left: '1.5rem'})
      }}>
      {content}
    </span>
  );
};

const upperProductCardBadge = ({
  badges,
  categoryId,
  hit,
  iframer,
  isMobile
}) => {
  if (!badges) return null;

  // Check for sale badge
  if (badges.sale_badge_ids?.includes(parseInt(hit.product_id, 10))) {
    return Badge({content: 'SALE', iframer, isMobile, secondary: true});
  }

  // Check for recommendation badges only if not a site-wide search
  const badgeTypes = ['bestSeller', 'customerFavorite', 'staffPick'];
  const badgeLabels = {
    bestSeller: 'Best\nSeller',
    customerFavorite: 'Customer\nFavorite',
    staffPick: 'Staff\nPick'
  };
  const activeCategoryId = categoryId || hit.primary_category_id;
  const foundBadge = badgeTypes.find((badgeType) =>
    hasRecommendationBadge({
      activeCategoryId,
      badgeName: badgeType,
      badges,
      hit
    })
  );
  if (foundBadge) {
    return Badge({
      content: badgeLabels[foundBadge],
      iframer,
      isMobile,
      recommendation: true
    });
  }

  // Check for new badge
  if (hasNewBadge({badges, hit})) {
    return Badge({content: 'NEW', iframer, isMobile});
  }

  return null;
};

function CustomHits(props) {
  const {
    algoliaIndex,
    backColor,
    badges,
    categoryId,
    categoryName,
    cid,
    compareHandler,
    compareStyles,
    currentFilters,
    customDesignPreview,
    defaultDesignPreview,
    defaultQuoteQty,
    designPreviewEnabledCategoryIds,
    filteredHits,
    firstImage,
    frontColor,
    iframer,
    indexName,
    internalCatalog,
    page,
    quantity,
    quickViewHandler,
    quoteQuantityDirty,
    renderSkeletons,
    scroll,
    setCustomDesignPreview,
    setDefaultDesignPreview,
    showStyleId,
    siteWideSearch,
    useDynamicPricing,
    ydhSettings
  } = props;

  const [renderedHits, setRenderedHits] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [defaultQuoteQtyMap, setDefaultQuoteQtyMap] = useState({});

  const isMobile = useMobileBreakpoint();
  const defaultUnitPriceTestValue = getDefaultUnitPriceTestValue(
    siteWideSearch,
    categoryId
  );
  const isDefaultUnitPriceEnabled = defaultUnitPriceTestValue === 'test';
  const isProductCardOptimizationtest = productCardOptimizationEnabled();

  useEffect(() => {
    if (defaultUnitPriceTestValue !== null) {
      window.dataLayer.push({
        ab_test_group: defaultUnitPriceTestValue,
        ab_test_location: 't-shirt plps',
        ab_test_name: '2024 08 01 default unit pricing',
        event: 'ab_test_group'
      });
    }
  }, [defaultUnitPriceTestValue]);

  useEffect(() => {
    if (filteredHits?.length > 0) {
      if (initialLoad && !iframer) {
        setRenderedHits(filteredHits.slice(0, getInitialCardCount()));
      } else {
        setRenderedHits(filteredHits);
      }
    }
  }, [initialLoad, filteredHits, iframer]);

  useEffect(() => {
    dataLayer.push({event: 'Hits Viewed'});
  }, [filteredHits]);

  useEffect(() => {
    if (scroll) {
      setInitialLoad(false);
    }
  }, [scroll]);

  useEffect(() => {
    // This useEffect can be eliminated if hit contains 'primary_category_default_quote_qty'
    if (siteWideSearch && algoliaIndex) {
      let isMounted = true;
      const primaryCategoryDefaultQty = {};

      algoliaIndex
        .browseRules({
          batch: (rules) => {
            rules.forEach((rule) => {
              const result = rule.objectID.toString().match(/^qr_(\d+)$/);
              const catId = result?.[1];
              if (catId && !primaryCategoryDefaultQty[catId]) {
                const defaultQty = parseInt(
                  rule?.consequence?.userData?.defaultQuoteQty,
                  10
                );
                if (defaultQty > 0)
                  primaryCategoryDefaultQty[catId] = defaultQty;
              }
            });
          }
        })
        .then(() => {
          if (isMounted) setDefaultQuoteQtyMap(primaryCategoryDefaultQty);
        })
        .catch((error) => {
          rollbar.error('Error browsing Algolia rules', error);
        });
      return () => {
        isMounted = false;
      };
    }
    return undefined;
  }, [algoliaIndex?.indexName, siteWideSearch]);

  const hasColorRefinements = () => {
    return (
      currentFilters.find(
        (refinementItem) => refinementItem.attribute === 'colors.simple_names'
      ) !== undefined
    );
  };

  // TODO: Remove after default unit price for T-shirts test is completed
  const getDefaultQuoteQty = (hit) => {
    // Show price per 30 units for all search results with primary category as subcategory of T-shirts category
    if (
      isDefaultUnitPriceEnabled &&
      siteWideSearch &&
      T_SHIRTS_SUBCATEGORIES_IDS.includes(hit.primary_category_id)
    ) {
      return DEFAULT_UNITS_TEST_NUMBER;
    }

    // Show price per 30 units for all subcategories for T-shirts category on PLP
    if (
      isDefaultUnitPriceEnabled &&
      T_SHIRTS_SUBCATEGORIES_IDS.includes(Number(categoryId))
    ) {
      return DEFAULT_UNITS_TEST_NUMBER;
    }

    return (
      defaultQuoteQty ||
      (siteWideSearch && defaultQuoteQtyMap[hit.primary_category_id])
    );
  };

  const isPreviewUploadEnabled = allowSettingCustomDesignPreview(
    iframer,
    designPreviewEnabledCategoryIds,
    parseInt(categoryId)
  );

  return (
    <div
      className="pc-Products"
      data-insights-index={indexName}
      style={{
        ...(isProductCardOptimizationtest && {
          paddingTop: 0,
          rowGap: '1.5rem',
          ...(!isMobile && {
            columnGap: '1px',
            marginLeft: '-0.75rem',
            rowGap: '2rem',
            width: 'calc(100% + 1.5rem)'
          })
        })
      }}>
      {isPreviewUploadEnabled && (
        <Box
          className="pc-ProductCard"
          sx={{
            display: isMobile ? 'block' : 'flex'
          }}>
          <UploadCard
            customDesignPreview={customDesignPreview}
            setCustomDesignPreview={setCustomDesignPreview}
          />
        </Box>
      )}
      {/* Skeleton */}
      {renderSkeletons && (
        <ProductCardPlaceholders
          isMobile={isMobile}
          firstImage={firstImage}
          isPreviewUploadEnabled={isPreviewUploadEnabled}
        />
      )}
      {/* Correct hits from Algolia */}
      {!renderSkeletons &&
        renderedHits?.map((hit, index) => {
          return (
            <ProductCard
              hit={hit}
              key={hit.product_id}
              currentFilters={currentFilters}
              categoryId={categoryId}
              categoryName={categoryName}
              defaultQuoteQty={getDefaultQuoteQty(hit)}
              customDesignPreview={customDesignPreview}
              defaultDesignPreview={defaultDesignPreview}
              setDefaultDesignPreview={setDefaultDesignPreview}
              designPreviewEnabledCategoryIds={designPreviewEnabledCategoryIds}
              siteWideSearch={siteWideSearch}
              upperProductCardBadge={upperProductCardBadge({
                badges,
                categoryId,
                hit,
                iframer,
                isMobile,
                siteWideSearch
              })}
              lowerProductCardBadge={lowerProductCardBadge({badges, hit})}
              colorFilterApplied={hasColorRefinements()}
              internalCatalog={internalCatalog}
              quickViewHandler={quickViewHandler}
              showStyleId={showStyleId}
              quantity={quantity}
              frontColor={frontColor}
              backColor={backColor}
              useDynamicPricing={useDynamicPricing}
              iframer={iframer}
              cid={cid}
              ydhSettings={ydhSettings}
              firstImage={firstImage}
              isFirstHit={page === 1 && index === 0}
              compareHandler={compareHandler}
              compareStyles={compareStyles}
              quoteQuantityDirty={quoteQuantityDirty}
            />
          );
        })}
    </div>
  );
}

export default CustomHits;
